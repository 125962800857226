import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // }
  //
  // 首页
  {
    path: '/',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Login/Login.vue')
  },
  // 小灵如意
  {
    path: '/smallspirt',
    name: 'smallspirt',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/smallSpiritRuyi/SmallSpiritRuyi.vue')
  },
  // 睿筹君
  {
    path: '/fartoraise',
    name: 'fartoraise',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/farcetoraise/Farcetoraise.vue')
  },
  // ROBOT
  {
    path: '/robot',
    name: 'robot',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/robot/Robot.vue')
  },
  // 关于我们
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/aboutUs/Aboutus.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
