import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 公共样式
import './assets/css/reset.css'

import ElementUI from 'element-ui'
// 数字跳动
import VueAnimateNumber from 'vue-animate-number'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)
Vue.use(VueAnimateNumber)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
